import { createRouter, createWebHistory } from 'vue-router'
import QuestionView from '../views/QuestionView.vue'
import FimView from '../views/FimView.vue'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/question/:id',
    name: 'question',
    component: QuestionView
  },
  {
    path: '/fim',
    name: 'fim',
    component: FimView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
