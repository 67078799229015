<template>
  <div v-if="false" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="overflow-y-auto bg-black bg-opacity-70 overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex flex-col items-center justify-center w-full md:inset-0 h-[calc(100%-1rem)] h-full">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Entenda as instruções
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                </p>
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                </p>
            </div>
            <!-- Modal footer -->
            <div class="flex items-center w-full p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button @click="popup" type="button" class="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-lg px-5 py-2.5 text-center">Entendi, começar agora</button>
            </div>
        </div>
    </div>
</div>
  <router-view/>
</template>

<script>

export default {
  data() {
    return {
      modal: false
    }
  },
  methods: {
    popup(){
      this.modal = false
    },
  },
  mounted() {
    if(location.pathname == "/question/1"){
      this.modal = true
    }

    const dados = JSON.parse(localStorage.getItem("dados"))

    if(dados?.nome){

      if(dados.fim == true){
        if(location.pathname !== "/fim"){
          location.href = `/fim`
        }
      }
      else if(location.pathname !== `/question/${Number(dados.respostas.length)+1}`){
        location.href = `/question/${Number(dados.respostas.length)+1}`
      }
      
    }else if(location.pathname !== "/"){
      location.href = "/"
    }

  }

}

</script>

<style>
#app {
  font-family: Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
