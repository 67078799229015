<template>
<div class="bg-white py-8 sm:py-12 lg:py-16">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">👋 Vamos começar!</h2>

    <div class="mx-auto max-w-lg rounded-lg border">
      <div class="flex flex-col gap-4 p-4 md:p-8">
        <div>
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Nome:</label>
          <input type="text" v-model="nome" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
        </div>

        <div>
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">E-mail:</label>
          <input type="text" v-model="email" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
        </div>

        <div>
          <label class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Whatsapp:</label>
          <input type="number" v-model="whatsapp" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
        </div>

        <button @click="iniciar" class="block text-lg rounded-lg bg-green-400  hover:bg-green-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 focus-visible:ring md:text-base">Iniciar questionário</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      nome: "",
      whatsapp: "",
      email: "",
    }
  },
  methods: {
    iniciar(){
      if(!this.nome || !this.whatsapp || !this.email){
        return alert("Preencha todos os campos.")
      }

      if(!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)){
        return alert("Preencha com um e-mail válido.")
      }

      if(String(this.whatsapp).length < 8){
        return alert("Preencha com um número de Whatsapp válido.")
      }

      localStorage.setItem("dados",JSON.stringify({nome: this.nome, whatsapp: this.whatsapp, email: this.email, respostas:[], fim: false}))

      location.href = "/question/1"
    }
  }

}

</script>
